

























































































































































































































































































































































































































































































































// @ts-nocheck
import Vue from "vue"
import mixins from "vue-typed-mixins"
import uniqid from "uniqid"
import firebase from "firebase"
import { mapGetters } from "vuex"

import type { Client } from "@/types/client"
import type { Game } from "@/types/game"
import { GameRunStatus } from "@/types/game"

import { uploadMedia, getBlobFromDataURL } from "@/services/storage.service"

import {
  CLIENT_GAME_ASSIGNMENT_TYPES,
  CLIENT_CUSTOM_INPUT_TYPES
} from "@/config"
import { MAX_LOGIN_LOGO_WIDTH, CLIENT_IMAGES_MAX_WIDTH } from "@/constants"

import { LoadingMixinFactory, getDefaultLoadingScopes } from "@/mixins/loading"

export default mixins(
  Vue.extend(LoadingMixinFactory.create(getDefaultLoadingScopes()))
).extend({
  metaInfo: {
    title: "Clients"
  },
  filters: {
    truncate: function (value, limit) {
      if (value.length > limit) {
        value = value.substring(0, limit - 3) + "..."
      }
      return value
    }
  },
  data() {
    return {
      counter: null,
      customInputTypes: CLIENT_CUSTOM_INPUT_TYPES,
      categories: ["Standard", "Templates"],
      object: null,
      showExpo: false,
      showTemplates: false,
      showPassword: false,
      password: null,
      loadingImage: false,
      newGameID: null,
      newTournamentGameID: null,
      restrictions: CLIENT_GAME_ASSIGNMENT_TYPES,
      registration: ["anonymous"],
      client: {
        name: "",
        description: ""
      },
      viewing: false,
      items: [],
      deleting: false,
      editing: false,
      adding: false,
      tmp: "",
      search: "",
      user: this.$store.getters.user,
      headers: [
        {
          text: "ID",
          value: "theKey",
          align: "left",
          sortable: false
        },
        {
          text: "Client",
          value: "name",
          align: "left",
          sortable: false
        },
        {
          text: "Added By",
          value: "user.firstname",
          align: "left",
          sortable: false
        },
        {
          text: "Game ID",
          value: "gameID",
          align: "left",
          sortable: false
        },
        {
          text: "Login",
          align: "left",
          sortable: false
        },
        {
          text: "Action",
          align: "center",
          sortable: false
        }
      ],
      activeTab: null,
      CLIENT_IMAGES_MAX_WIDTH,
      MAX_LOGIN_LOGO_WIDTH,
      clients: [] as Client[],
      masters: [] as Game[],
      clientGames: [] as Game[]
    }
  },
  computed: {
    ...mapGetters("auth", ["token"]),
    orgID(): string {
      return this.$store.state.orgID
    },
    orgs() {
      var arr = Object.entries(this.$store.getters.orgs)
      var newArr = []
      for (var i in arr) {
        var obj = {}
        obj.id = arr[i][0]
        obj.name = arr[i][1].name
        newArr.push(obj)
      }
      return newArr
    },
    restriction: {
      get() {
        return this.client && this.client.restriction
          ? this.client.restriction || 0
          : 0
      },
      set(value) {
        this.client.restriction = value
      }
    },
    allClients() {
      const { clients } = this
      clients.reverse()

      if (this.showExpo) {
        return clients.filter(item => item.tournament)
      }

      if (this.showTemplates) {
        return clients.filter(item => item.category === "Templates")
      }

      return clients
    },
    images() {
      return this.client.images || new Array(6).fill("")
    }
  },
  watch: {
    editing(to) {
      this.cancel(to)
    },
    viewing(to) {
      this.cancel(to)
    },
    deleting(to) {
      this.cancel(to)
    },
    client(value) {
      if (value && value.theKey) {
        this.getClientGames(value.theKey)
      }
    }
  },
  mounted() {
    this.getClients()
    this.getMasters()
    this.$refs.clientTable.defaultPagination.rowsPerPage = 20
  },
  methods: {
    getClients() {
      this.setLoading("default", true)
      this.$services
        .getService("client")
        .then(service => service.getClients(this.orgID))
        .then(clients => {
          this.clients = clients
        })
        .finally(() => {
          this.setLoading("default", false)
        })
    },
    getClientGames(clientID: string) {
      this.$services
        .getService("game")
        .then(service => service.getGamesByClientID(this.orgID, clientID))
        .then(games => {
          this.clientGames = games
        })
    },
    getMasters() {
      this.$services
        .getService("game")
        .then(service =>
          service.getGamesByRunStatus(this.orgID, GameRunStatus.Masters)
        )
        .then(masters => {
          console.log(masters)
          this.masters = masters
        })
    },
    async setImage(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        this.loadingImage = true
        const fileName = `gamephotos/${uniqid()}-${this.client.name}.png`
        this.client.logoImage = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        console.log("which type = " + this.client.logoImage)
        this.loadingImage = false
      } catch (error) {
        console.log(error)
      }
    },
    async removeGame(game) {
      console.log("game", game)
      const { clientID } = game
      const orgID = this.$store.getters.orgID
      const gameID = game.theKey
      if (!orgID || !clientID || !gameID)
        throw new Error("Something went wrong")

      const promises = [
        firebase
          .database()
          .ref(`clients/${clientID}/games/${gameID}`)
          .set(null),
        firebase.database().ref(`org/${orgID}/game/${gameID}`).set(null),
        firebase.database().ref(`org/${orgID}/games/${gameID}`).set(null)
      ]

      if (this.client.gameID && this.client.gameID === gameID) {
        const promise = firebase
          .database()
          .ref(`client/${clientID}/gameID`)
          .set(null)
        promises.push(promise)
      }

      await Promise.all(promises)
    },
    async addGame() {
      if (!this.newTournamentGameID) throw new Error("No game ID is given")
      if (!this.client.theKey) throw new Error("No client is given")

      const game = await this.$services
        .getService("game")
        .then(service =>
          service.getGameByID(this.orgID, this.newTournamentGameID)
        )

      if (!game) throw new Error("Game data is undefined")

      game.clientID = this.client.theKey
      game.theKey = this.newTournamentGameID
      game.sameClientID = true
      game.runStatus = "Tournament"
      console.log("Game to copy", game)
      try {
        const newGameID = await this.$store.dispatch("Games/copyGame", {
          game: game
        })
        console.log("New game created", newGameID)
      } catch (e) {
        console.error("Error in Games/copyGame")
        console.error(e)
      }
    },
    resetForm() {
      this.client = {
        name: "",
        avatar: ""
      }
    },
    cancel(to) {
      if (to === false) {
        this.resetForm()
      }
    },
    view(client) {
      this.viewing = true
      this.client = { ...client }
    },
    closeModalDetail() {
      this.viewing = false
      this.client = null
    },
    openRemoveDialog(client) {
      this.client = { ...client }
      this.deleting = true
    },
    async remove() {
      await this.$services
        .getService("client")
        .then(service => service.deleteClient(this.client.id))
      this.deleting = false
      this.clients = this.clients
        .filter(client => client.id !== this.client.id)
        .reverse()
    },
    imagesDeleteHandler(index) {
      this.$set(this.client.images, index, "")
    },
    async updateImages(payload, index) {
      try {
        const { client } = this
        const array = client.images || new Array(6).fill("")
        const blob = await getBlobFromDataURL(payload.dataUrl)
        const fileName = `gamephotos/${uniqid()}-${client.name}.png`
        const url = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
        array[index] = url
        this.$set(this.client, "images", array)
      } catch (error) {
        console.log(error)
      }
    },
    async setLoginLogo(e) {
      try {
        const blob = await getBlobFromDataURL(e.dataUrl)
        const fileName = `gamephotos/${uniqid()}-${this.client.name}.png`
        this.client.loginLogo = await uploadMedia({
          fileName,
          blob,
          token: this.token
        })
      } catch (error) {
        console.log(error)
      }
    },
    deleteLoginLogo() {
      this.client.loginLogo = ""
    },
    async edit(client) {
      this.client = { ...client, regularLogin: !!client.regularLogin }

      const snapshot = await firebase
        .database()
        .ref(`auth/client/${this.client.theKey}/password`)
        .once("value")

      this.password = snapshot.val()
      this.newGameID = client.gameID
      this.editing = true
      this.adding = false
    },
    async add() {
      this.newGameID = null
      this.password = null
      this.editing = true
      this.adding = true
      this.client = {
        name: "",
        description: ""
      }
    },
    async copy() {
      this.client.dateAdded = new Date()
      const { id } = await await this.$services
        .getService("client")
        .then(service => service.addClient(this.client))
      const games = this.clientGames.filter(
        ({ deletedTimestamp }) => !deletedTimestamp
      )
      this.counter = 0
      for (var i in games) {
        this.counter++
        const game = { ...games[i] }
        game.clientID = clientID
        game.orgID = this.client.orgID
        game.sameClientID = true
        await this.$store.dispatch("Games/copyGame", { game })
      }

      await this.$store.dispatch("recording/copyRecordingsByClient", {
        clientID,
        srcTargetID: this.client.id
      })
      this.counter = null
      this.editing = false
    },
    async update() {
      this.editing = false

      const client = { ...this.client, user: this.user }
      const { theKey: clientID } = client

      if (client.regularLogin) this.password = null

      if (!this.password) {
        await firebase
          .database()
          .ref(`auth/client/${clientID}`)
          .update({ auth: null, password: null, user: null })
      } else {
        await firebase
          .database()
          .ref(`auth/client/${clientID}`)
          .update({ auth: true, password: this.password })
      }

      const updateGameObjects = async (clientID, orgID, gameID) => {
        await Promise.all([
          firebase
            .database()
            .ref(`org/${orgID}/games/${gameID}/clientID`)
            .set(clientID),
          firebase
            .database()
            .ref(`org/${orgID}/game/${gameID}/clientID`)
            .set(clientID)
        ])
      }

      if (this.adding) {
        client.orgID = this.$store.getters.orgID
        if (this.newGameID) {
          client.gameID = this.newGameID
          const { orgID } = client
          await updateGameObjects({ gameID: this.newGameID, clientID, orgID })
        }
        const createdClient = await this.$services
          .getService("client")
          .then(service => service.addClient(client))
        this.clients.unshift(createdClient)
        this.clients.reverse()
        this.adding = false
      } else {
        if (this.newGameID) {
          client.gameID = this.newGameID
          const { orgID } = client
          await updateGameObjects({ gameID: this.newGameID, clientID, orgID })
        }
        const updatedClient = await this.$services
          .getService("client")
          .then(service => service.updateClient(client))

        if (updatedClient) {
          this.updateClient(client.id, updatedClient)
        }
      }
    },
    updateClient(id: string, client: Client) {
      const index = this.clients.findIndex(client => client.id === id)

      if (index !== -1) {
        this.$set(this.clients, index, client)
      }

      this.clients.reverse()
    },
    async turnToExpo() {
      if (confirm("This action is irreversible")) {
        const orgID = this.$store.getters.orgID
        const gameID = this.client.gameID
        const obj = await this.$services
          .getService("game")
          .then(service => service.getGameByID(this.orgID, gameID))
        if (!obj) throw new Error("Game copy error")
        const game = { ...obj }

        if (!this.client.theKey) throw new Error("No client is given")
        if (!game) throw new Error("Game data is undefined")

        game.clientID = this.client.theKey
        game.theKey = gameID
        game.sameClientID = true
        game.runStatus = "Tournament"

        const newGameID = await this.$store.dispatch("Games/copyGame", {
          game
        })

        await firebase.database().ref(`org/${orgID}/game/${gameID}`).set(null)
        await firebase.database().ref(`org/${orgID}/games/${gameID}`).set(null)

        this.client.tournament = true
        this.client.games = {}
        this.client.games[newGameID] = true

        await this.update()
      } else {
        alert("Aborted.")
      }
    }
  }
})
