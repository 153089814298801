export interface Game {
  id: string
  clientID: string
  hostUserID: string
  runStatus: GameRunStatus
}

export enum GameRunStatus {
  Masters = "Masters",
  Blocks = "Blocks",
  Tournament = "Tournament",
  Deleted = "Deleted"
}
