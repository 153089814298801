var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "dashboard-client-page" },
    [
      _c(
        "v-card-title",
        [
          _c("h1", [_vm._v("Clients")]),
          _c("v-checkbox", {
            staticStyle: { "margin-left": "30px", "margin-top": "-5px" },
            attrs: { dense: "", label: "Only Expo", "hide-details": "" },
            model: {
              value: _vm.showExpo,
              callback: function($$v) {
                _vm.showExpo = $$v
              },
              expression: "showExpo"
            }
          }),
          _c("v-checkbox", {
            staticStyle: { "margin-left": "30px", "margin-top": "-5px" },
            attrs: { dense: "", label: "Templates", "hide-details": "" },
            model: {
              value: _vm.showTemplates,
              callback: function($$v) {
                _vm.showTemplates = $$v
              },
              expression: "showTemplates"
            }
          }),
          _c("v-spacer"),
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Search",
              "single-line": "",
              "hide-details": ""
            },
            model: {
              value: _vm.search,
              callback: function($$v) {
                _vm.search = $$v
              },
              expression: "search"
            }
          })
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: {
            circle: "",
            absolute: "",
            top: "",
            small: "",
            right: "",
            fab: "",
            color: "orange"
          },
          on: {
            click: function($event) {
              return _vm.add()
            }
          }
        },
        [_c("v-icon", [_vm._v("add")])],
        1
      ),
      _c("v-data-table", {
        ref: "clientTable",
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.allClients,
          search: _vm.search,
          loading: _vm.isLoading("default"),
          "item-key": "id"
        },
        scopedSlots: _vm._u([
          {
            key: "items",
            fn: function(props) {
              return [
                _c("tr", [
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(" " + _vm._s(props.item.theKey) + " ")
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.edit(props.item)
                          }
                        }
                      },
                      [_vm._v(_vm._s(props.item.name))]
                    )
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          props.item.user ? props.item.user.firstname : ""
                        ) +
                        " "
                    )
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _vm._v(_vm._s(props.item.gameID))
                  ]),
                  _c("td", { staticClass: "text-xs-left" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "/gamelogin/" + props.item.theKey + "?auth=0",
                          target: "_blank"
                        }
                      },
                      [_vm._v(" Login ")]
                    ),
                    _c("br"),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "/gamelogin/" +
                            props.item.theKey +
                            "?audit=1&auth=0",
                          target: "_blank"
                        }
                      },
                      [_c("i", [_vm._v(" Audit")])]
                    )
                  ]),
                  _c(
                    "td",
                    {
                      staticClass: "text-xs-center",
                      attrs: { width: "250px" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            link: "",
                            color: "white",
                            light: "",
                            small: "",
                            fab: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.edit(props.item)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("edit")])],
                        1
                      ),
                      _vm.user.super
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                link: "",
                                color: "white",
                                light: "",
                                small: "",
                                fab: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openRemoveDialog(props.item)
                                }
                              }
                            },
                            [_c("v-icon", [_vm._v("delete")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              ]
            }
          },
          {
            key: "pageText",
            fn: function(ref) {
              var pageStart = ref.pageStart
              var pageStop = ref.pageStop
              return [
                _vm._v(
                  " From " + _vm._s(pageStart) + " to " + _vm._s(pageStop) + " "
                )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500px" },
          model: {
            value: _vm.deleting,
            callback: function($$v) {
              _vm.deleting = $$v
            },
            expression: "deleting"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "highlight", dark: "" } },
                [_c("v-toolbar-title", [_vm._v(" Delete Client")])],
                1
              ),
              _c("v-card-text", [
                _vm._v("Are you sure want to delete the client called "),
                _c("b", [_vm._v(_vm._s(_vm.client.name))]),
                _vm._v("? NOTE: This does not delete the game")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "error",
                          attrs: { flat: "" },
                          on: { click: _vm.remove }
                        },
                        [_vm._v("Remove")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "" },
                          on: {
                            click: function($event) {
                              _vm.deleting = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "75%" },
          model: {
            value: _vm.editing,
            callback: function($$v) {
              _vm.editing = $$v
            },
            expression: "editing"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "highlight", dark: "" } },
                    [
                      _c("v-icon", { attrs: { dark: "" } }, [_vm._v("edit")]),
                      _c("v-toolbar-title", [
                        _vm._v(" " + _vm._s(_vm.client.name))
                      ]),
                      _c("v-spacer"),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.update } },
                        [_c("v-icon", [_vm._v("saves")])],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("cancel")])],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.client
                    ? _c(
                        "div",
                        [
                          _c(
                            "v-tabs",
                            {
                              attrs: { dark: "" },
                              model: {
                                value: _vm.activeTab,
                                callback: function($$v) {
                                  _vm.activeTab = $$v
                                },
                                expression: "activeTab"
                              }
                            },
                            [
                              _c(
                                "v-tab",
                                { key: "general", attrs: { ripple: "" } },
                                [_vm._v(" General ")]
                              ),
                              _c(
                                "v-tab",
                                { key: "Login", attrs: { ripple: "" } },
                                [_vm._v(" Login ")]
                              ),
                              _c(
                                "v-tab",
                                { key: "Images", attrs: { ripple: "" } },
                                [_vm._v(" Images ")]
                              ),
                              _c(
                                "v-tab-item",
                                { key: "general" },
                                [
                                  _c(
                                    "v-layout",
                                    { attrs: { row: "", wrap: "" } },
                                    [
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm5: "",
                                            "mx-2": ""
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Name",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.client.name,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "name",
                                                  $$v
                                                )
                                              },
                                              expression: "client.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm5: "",
                                            "mx-2": ""
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Redirect URL",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.client.redirectURL,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "redirectURL",
                                                  $$v
                                                )
                                              },
                                              expression: "client.redirectURL"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            "d-flex": "",
                                            xs12: "",
                                            "mx-2": ""
                                          }
                                        },
                                        [
                                          _c(
                                            "v-layout",
                                            { attrs: { row: "" } },
                                            [
                                              _c(
                                                "v-flex",
                                                { attrs: { xs3: "" } },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items:
                                                        _vm.customInputTypes,
                                                      label: "CUSTOM INPUT",
                                                      "item-text": "label",
                                                      "item-value": "value"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.client
                                                          .customInputType,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.client,
                                                          "customInputType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "client.customInputType"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _vm.client.customInputType
                                                ? _c(
                                                    "v-flex",
                                                    { attrs: { xs3: "" } },
                                                    [
                                                      _c("v-text-field", {
                                                        attrs: {
                                                          label:
                                                            "Custom Input Label"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.client
                                                              .customInputLabel,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.client,
                                                              "customInputLabel",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "client.customInputLabel"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.client.customInputType ===
                                              "select"
                                                ? _c(
                                                    "v-flex",
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          label:
                                                            "Custom Input Options (comma separated)"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.client
                                                              .customInputOptions,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.client,
                                                              "customInputOptions",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "client.customInputOptions"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs12: "",
                                            sm5: "",
                                            "mx-2": ""
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Description",
                                              required: ""
                                            },
                                            model: {
                                              value: _vm.client.description,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "description",
                                                  $$v
                                                )
                                              },
                                              expression: "client.description"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs12: "", "mx-2": "" } },
                                        [
                                          _vm._v(" Pick Different Gamer "),
                                          _c("v-text-field", {
                                            attrs: {
                                              label: "Choose From Client Games",
                                              "item-text": "name",
                                              "item-value": "id"
                                            },
                                            model: {
                                              value: _vm.newGameID,
                                              callback: function($$v) {
                                                _vm.newGameID = $$v
                                              },
                                              expression: "newGameID"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs3: "",
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "Email Login" },
                                            model: {
                                              value: _vm.client.regularLogin,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "regularLogin",
                                                  $$v
                                                )
                                              },
                                              expression: "client.regularLogin"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      !_vm.client.regularLogin
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "form",
                                                {
                                                  attrs: {
                                                    autocomplete: "off"
                                                  },
                                                  on: {
                                                    submit: function($event) {
                                                      $event.preventDefault()
                                                      return (function() {})(
                                                        $event
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      autocomplete:
                                                        "new-password",
                                                      placeholder: " ",
                                                      "append-icon": _vm.showPassword
                                                        ? "visibility"
                                                        : "visibility_off",
                                                      type: _vm.showPassword
                                                        ? "text"
                                                        : "password",
                                                      label: "Optional Password"
                                                    },
                                                    on: {
                                                      "click:append": function(
                                                        $event
                                                      ) {
                                                        _vm.showPassword = !_vm.showPassword
                                                      }
                                                    },
                                                    model: {
                                                      value: _vm.password,
                                                      callback: function($$v) {
                                                        _vm.password = $$v
                                                      },
                                                      expression: "password"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-flex",
                                        { attrs: { xs3: "" } },
                                        [
                                          !_vm.client.tournament
                                            ? _c(
                                                "v-btn",
                                                {
                                                  on: { click: _vm.turnToExpo }
                                                },
                                                [_vm._v("Turn To Expo")]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs3: "",
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: { label: "LogRocket" },
                                            model: {
                                              value: _vm.client.logrocket,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "logrocket",
                                                  $$v
                                                )
                                              },
                                              expression: "client.logrocket"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-flex",
                                        {
                                          attrs: {
                                            xs3: "",
                                            "d-flex": "",
                                            "align-center": ""
                                          }
                                        },
                                        [
                                          _c("v-switch", {
                                            attrs: {
                                              label: "Onboarding Team Select"
                                            },
                                            model: {
                                              value:
                                                _vm.client.showChooseGameCard,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.client,
                                                  "showChooseGameCard",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "client.showChooseGameCard"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  !!_vm.client.tournament
                                    ? _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs3: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.categories,
                                                  label: "What Category"
                                                },
                                                model: {
                                                  value: _vm.client.category,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "category",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "client.category"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs3: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: { label: "Locked" },
                                                model: {
                                                  value: _vm.client.locked,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "locked",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "client.locked"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs3: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c("v-switch", {
                                                attrs: { label: "Email Login" },
                                                model: {
                                                  value:
                                                    _vm.client.regularLogin,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "regularLogin",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "client.regularLogin"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: {
                                                xs3: "",
                                                "d-flex": "",
                                                "align-center": ""
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.restrictions,
                                                  label: "Game Assignment",
                                                  "item-text": "text",
                                                  "item-value": "code"
                                                },
                                                model: {
                                                  value: _vm.restriction,
                                                  callback: function($$v) {
                                                    _vm.restriction = $$v
                                                  },
                                                  expression: "restriction"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm._l(_vm.clientGames, function(
                                            game,
                                            i
                                          ) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key:
                                                  "client-game-" +
                                                  game.theKey +
                                                  "-" +
                                                  i,
                                                attrs: { xs4: "" }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(game.name) + " "
                                                ),
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      small: "",
                                                      icon: ""
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.removeGame(
                                                          game
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v("delete")
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm.client.tournament
                                    ? _c(
                                        "v-layout",
                                        { attrs: { row: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { "mt-3": "", xs4: "" } },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.masters,
                                                  label:
                                                    "Copy Game To Tournament",
                                                  "item-text": "name",
                                                  "item-value": "id"
                                                },
                                                model: {
                                                  value:
                                                    _vm.newTournamentGameID,
                                                  callback: function($$v) {
                                                    _vm.newTournamentGameID = $$v
                                                  },
                                                  expression:
                                                    "newTournamentGameID"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          !!_vm.newTournamentGameID
                                            ? _c(
                                                "v-flex",
                                                { attrs: { xs2: "" } },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        "mt-3": "",
                                                        icon: ""
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addGame()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("v-icon", [
                                                        _vm._v("add")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs3: "", "mx-2": "" } },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  width: "150",
                                                  src: _vm.client.logoImage
                                                }
                                              }),
                                              _c("image-uploader", {
                                                attrs: {
                                                  debug: 1,
                                                  "max-width": 1200,
                                                  quality: 1,
                                                  "auto-rotate": true,
                                                  preview: false,
                                                  "class-name": [
                                                    "fileinput",
                                                    {
                                                      "fileinput--loaded":
                                                        _vm.client.logoImage
                                                    }
                                                  ],
                                                  "output-format": "verbose",
                                                  capture: "environment",
                                                  accept: ".jpg,.png,.jpeg"
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.setImage(
                                                      $event,
                                                      _vm.client
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("v-text-field", {
                                                attrs: { label: "Photo URL" },
                                                model: {
                                                  value: _vm.client.logoImage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "logoImage",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "client.logoImage"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _vm.user.super
                                            ? _c(
                                                "v-flex",
                                                {
                                                  attrs: {
                                                    xs12: "",
                                                    "mx-2": ""
                                                  }
                                                },
                                                [
                                                  _c("v-select", {
                                                    attrs: {
                                                      items: _vm.orgs,
                                                      label:
                                                        "Copy To Organization",
                                                      "item-text": "name",
                                                      "item-value": "id"
                                                    },
                                                    model: {
                                                      value: _vm.client.orgID,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.client,
                                                          "orgID",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "client.orgID"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "login" },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: { fluid: "", "grid-list-md": "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-switch", {
                                                attrs: { label: "Hide GoGame" },
                                                model: {
                                                  value: _vm.client.hideGoGame,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "hideGoGame",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "client.hideGoGame"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-switch", {
                                                attrs: { label: "Use Avatar" },
                                                model: {
                                                  value: _vm.client.noUserPhoto,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "noUserPhoto",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "client.noUserPhoto"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs2: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: { label: "Tagline" },
                                                model: {
                                                  value: _vm.client.tagline,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "tagline",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "client.tagline"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            {
                                              attrs: { "d-flex": "", xs12: "" }
                                            },
                                            [
                                              _c(
                                                "v-layout",
                                                { attrs: { row: "" } },
                                                [
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs3: "" } },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          items:
                                                            _vm.customInputTypes,
                                                          label: "Custom input",
                                                          "item-text": "label",
                                                          "item-value": "value"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.client
                                                              .customInputType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.client,
                                                              "customInputType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "client.customInputType"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.client.customInputType
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs3: "" } },
                                                        [
                                                          _c("v-text-field", {
                                                            attrs: {
                                                              label:
                                                                "Custom Input Label"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.client
                                                                  .customInputLabel,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.client,
                                                                  "customInputLabel",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "client.customInputLabel"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-flex",
                                                    { attrs: { xs3: "" } },
                                                    [
                                                      _c("v-textarea", {
                                                        attrs: {
                                                          name: "input-7-1",
                                                          label:
                                                            "Country Blacklsit",
                                                          hint:
                                                            "Comma separated list"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.client
                                                              .blacklist,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.client,
                                                              "blacklist",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "client.blacklist"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm.client.customInputType ===
                                                  "select"
                                                    ? _c(
                                                        "v-flex",
                                                        { attrs: { xs3: "" } },
                                                        [
                                                          _c("v-textarea", {
                                                            attrs: {
                                                              label:
                                                                "Custom Input Options (comma separated)"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.client
                                                                  .customInputOptions,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.client,
                                                                  "customInputOptions",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "client.customInputOptions"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-tab-item",
                                { key: "images" },
                                [
                                  _c(
                                    "v-container",
                                    {
                                      attrs: { fluid: "", "grid-list-md": "" }
                                    },
                                    [
                                      _c(
                                        "v-layout",
                                        { attrs: { row: "", wrap: "" } },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs5: "" } },
                                            [
                                              _vm._v(" Top Left Image "),
                                              _c("img", {
                                                attrs: {
                                                  width: "150",
                                                  src: _vm.client.logoImage
                                                }
                                              }),
                                              _c("image-uploader", {
                                                attrs: {
                                                  debug: 1,
                                                  "max-width": 1200,
                                                  quality: 1,
                                                  "auto-rotate": true,
                                                  preview: false,
                                                  "class-name": [
                                                    "fileinput",
                                                    {
                                                      "fileinput--loaded":
                                                        _vm.client.logoImage
                                                    }
                                                  ],
                                                  "output-format": "verbose",
                                                  capture: "environment",
                                                  accept: ".jpg,.png,.jpeg"
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.setImage(
                                                      $event,
                                                      _vm.client
                                                    )
                                                  }
                                                }
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: "Top Left Photo"
                                                },
                                                model: {
                                                  value: _vm.client.logoImage,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.client,
                                                      "logoImage",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "client.logoImage"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs5: "" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "caption" },
                                                [_vm._v("Login logo")]
                                              ),
                                              _vm.client.loginLogo
                                                ? _c("img", {
                                                    staticClass:
                                                      "rtb-max-w-full",
                                                    attrs: {
                                                      src: _vm.client.loginLogo,
                                                      alt: "Login logo"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _c("image-uploader", {
                                                attrs: {
                                                  debug: 1,
                                                  "max-width":
                                                    _vm.MAX_LOGIN_LOGO_WIDTH,
                                                  "auto-rotate": "",
                                                  preview: false,
                                                  "class-name": [
                                                    "fileinput",
                                                    {
                                                      "fileinput--loaded":
                                                        _vm.client.loginLogo
                                                    }
                                                  ],
                                                  "output-format": "verbose",
                                                  capture: "environment",
                                                  accept: ".jpg, .png, .jpeg"
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.setLoginLogo(
                                                      $event,
                                                      _vm.client
                                                    )
                                                  }
                                                }
                                              }),
                                              !!_vm.client.loginLogo
                                                ? _c("v-text-field", {
                                                    staticClass: "mt-4",
                                                    attrs: {
                                                      disabled: "",
                                                      label: "Login logo URL",
                                                      "hide-details": ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.client.loginLogo,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.client,
                                                          "loginLogo",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "client.loginLogo"
                                                    }
                                                  })
                                                : _vm._e(),
                                              !!_vm.client.loginLogo
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass: "ml-0",
                                                      attrs: {
                                                        color: "error",
                                                        depressed: "",
                                                        small: ""
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.deleteLoginLogo
                                                      }
                                                    },
                                                    [_vm._v("Delete")]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          ),
                                          _vm._l(_vm.images, function(
                                            image,
                                            index
                                          ) {
                                            return _c(
                                              "v-flex",
                                              {
                                                key: index,
                                                attrs: { xs2: "" }
                                              },
                                              [
                                                _vm._v(" LOGIN PAGE IMAGE "),
                                                image
                                                  ? _c("img", {
                                                      staticClass:
                                                        "rtb-max-w-full",
                                                      attrs: {
                                                        src: image,
                                                        alt: "Image"
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _c("image-uploader", {
                                                  attrs: {
                                                    debug: 1,
                                                    "max-width":
                                                      _vm.CLIENT_IMAGES_MAX_WIDTH,
                                                    "auto-rotate": "",
                                                    preview: false,
                                                    "class-name": [
                                                      "fileinput",
                                                      {
                                                        "fileinput--loaded": image
                                                      }
                                                    ],
                                                    "output-format": "verbose",
                                                    capture: "environment",
                                                    accept: ".jpg, .png, .jpeg"
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.updateImages(
                                                        $event,
                                                        index
                                                      )
                                                    }
                                                  }
                                                }),
                                                image
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        staticClass: "ml-0",
                                                        attrs: {
                                                          color: "error",
                                                          depressed: "",
                                                          small: ""
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.imagesDeleteHandler(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Delete")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          })
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-center": "" } },
                    [
                      _vm.editing
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { loading: _vm.counter, flat: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Save")]
                          )
                        : _vm._e(),
                      _vm.adding
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "primary",
                              attrs: { loading: _vm.counter, flat: "" },
                              on: { click: _vm.update }
                            },
                            [_vm._v("Add")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { flat: "" },
                          on: { click: _vm.copy }
                        },
                        [_vm._v("Copy " + _vm._s(_vm.counter) + " ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "secondary",
                          attrs: { flat: "", loading: _vm.counter },
                          on: {
                            click: function($event) {
                              _vm.editing = false
                            }
                          }
                        },
                        [_vm._v("Cancel")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }